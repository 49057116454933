import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from '../../containers/SaasModern/sassModern.style';

import Navbar from '../../containers/SaasModern/Download/Navbar';
import Instructions from '../../containers/SaasModern/Download/Instructions';
import CommentsSection from '../../containers/SaasModern/Comments';
import Footer from '../../containers/SaasModern/Footer';
import SEO from '../../components/seo';
import { Helmet } from 'react-helmet';
import Layout from '../../components/layout';
import { connect } from 'react-redux';

const mapStateToProps = ({ affiliate, adSense }) => {
  return { affiliate, adSense };
};

class Download extends React.Component {
  render() {
    const { affiliate, adSense } = this.props;

    let downloadUrl = 'https://www.printablecal.com/download/PrintableCal_Setup.exe';

    if (affiliate) {
      downloadUrl = 'https://www.printablecal.com/download/affiliate/PrintableCal_Setup.exe';
    } else if (adSense) {
      downloadUrl = 'https://www.printablecal.com/download/adsense/PrintableCal_Setup.exe';
    }

    const refreshUrl = `1;URL=${downloadUrl}`;

    return (
      <Layout>
        <Fragment>
          <SEO
            title="Download PrintableCal"
            description="Download a free calendar maker for Excel, Word, and PowerPoint. Over 60 calendar templates are included and can be populated with holidays and schedules from a wide variety of sources, including Google Calendar, Outlook, iCloud, and more."
            keywords="printable calendar, download printable calendar, install printable calendar, calendar template, calendar add-in, Excel calendar, Word calendar, 2020 calendar, holiday calendar"
          />
          <Helmet>
            <meta httpEquiv="refresh" content={refreshUrl} />
            <script>
              {`gtag('event', 'conversion', {'send_to': 'AW-1037981043/K413CNXm-rcBEPOq-e4D'});`}
            </script>
          </Helmet>
          <ResetCSS />
          <GlobalStyle />

          <ContentWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar />
              </DrawerProvider>
            </Sticky>
            <Instructions downloadUrl={downloadUrl} />
            <div
              style={{
                background: '#f7f7f7',
                padding: '48px 0 0 0',
                margin: '48px 0 0 0',
              }}
            >
              <CommentsSection
                showHeading={false}
                url="https://www.printablecal.com/Download"
                identifier="commentsplus_post_93_489"
                title="Download PrintableCal Add-in For Excel and Word"
              />
            </div>
            <Footer />
          </ContentWrapper>
        </Fragment>
      </Layout>
    );
  }
}

export default connect(mapStateToProps)(Download);
