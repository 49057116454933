import React from 'react';
import Container from 'common/src/components/UI/Container';
import Heading from 'reusecore/src/elements/Heading';
import Image from 'reusecore/src/elements/Image';
import PropTypes from 'prop-types';
import DownloadRunImage from '../../../../../../common/src/assets/image/download_run.png';
import RibbonImage from '../../../../../../common/src/assets/image/ribbon.png';
import InstructionsWrapper from './instructions.style';
import { Link } from 'gatsby';

const Instructions = ({ secHeading, secContent, downloadUrl }) => {
  return (
    <Container>
      <InstructionsWrapper>
        <Heading
          {...secHeading} as="h1"
          content="Thank you for downloading PrintableCal!"
        />
        <div {...secContent}>
          <p>
            Your download should begin shortly. If it doesn't,{' '}
            <a href={downloadUrl}>click here to download</a>. PrintableCal runs
            on Windows and requires Microsoft Office 365 / Office 2007 or later. PrintableCal is fully compatible with 64-bit versions of Office, including Office 2021.
          </p>
        </div>
        <Heading {...secHeading} content="Installation instructions" />
        <div {...secContent}>
          <ol>
            <li>
              Open or run <b>PrintableCal_Setup.exe</b> after it finishes
              downloading.
              <br />
              <Image src={DownloadRunImage} alt="" style={{ width: 428 }} />
            </li>
            <li>
              The PrintableCal Setup window will appear. Click the{' '}
              <b>I agree to the license terms and conditions</b> box after
              reviewing the license terms (if you are so inclined), and then
              click the <b>Next</b> button.
            </li>
            <li>
              The installer can't run if Excel, Word, or PowerPoint are open. If
              you see a red X next to <b>Running applications</b>, exit all
              instances of Excel, Word, and PowerPoint. Click the <b>Refresh</b>{' '}
              button. If you still see a red X, try rebooting. Otherwise, click
              the <b>Install</b> button to continue.
            </li>
            <li>
              If a <b>User Access Control</b> window appears, click the{' '}
              <b>Yes</b> button to continue the setup. Any missing prerequisites
              (except Microsoft Office) will be automatically installed. A
              progress bar will show the installation status. On most computers,
              installation should only take a few seconds.
            </li>
            <li>
              After the installation has completed, a window will appear to let
              you know PrintableCal has been successfully installed.
            </li>
            <li>
              To access PrintableCal, start Excel, Word, or PowerPoint and look
              for the <b>PrintableCal</b> tab on the ribbon menu. See the {' '}
              <Link to="/Documentation/Quick-Start">Quick Start</Link> for
              instructions on getting started with PrintableCal.
              <Image src={RibbonImage} alt="" style={{ width: 850 }} />
            </li>
          </ol>
        </div>
        <Heading
          {...secHeading}
          content="If the PrintableCal tab doesn't appear on the ribbon menu..."
        />
        <div {...secContent}>
          <p>
            If the PrintableCal tab doesn't appear on the ribbon menu in Excel,
            Word, or PowerPoint, try rebooting.
          </p>
          <p>
            If rebooting doesn't fix it, download and run the{' '}
            <a href="https://www.printablecal.com/download/AddPrintableCal.exe">
              Add PrintableCal utility
            </a>{' '}
            under the account where the PrintableCal tab isn't appearing. This
            utility does not require admin privileges.
          </p>
          <p>
            If the <strong>Add PrintableCal</strong> utility doesn't solve the
            problem, download the registry fix linked below, depending on which
            version of Office you're using:
          </p>
          <ul>
            <li>
              <a href="https://www.printablecal.com/download/PrintableCalRegistryFix.zip">
                PrintableCal registry fix for Office 2010 and later
              </a>
            </li>
            <li>
              <a href="https://www.printablecal.com/download/PrintableCalRegistryFix_Office2007.zip">
                PrintableCal registry fix for Office 2007 only
              </a>
            </li>
          </ul>
          <p>
            After downloading, extract the file to a location on your hard
            drive. Also close all instances of Excel and Word. To add
            PrintableCal to Excel, double-click the file named{' '}
            <b>PrintableCal - Excel.reg</b>. To add to Word, double-click{' '}
            <b>PrintableCal - Word.reg</b>. Note, you’ll need administrator
            rights for the scripts to run.
          </p>
        </div>
      </InstructionsWrapper>
    </Container>
  );
};

Instructions.propTypes = {
  secHeading: PropTypes.object,
  secContent: PropTypes.object,
};

Instructions.defaultProps = {
  secHeading: {
    textAlign: 'left',
    fontSize: '28px',
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67',
  },
  secContent: {
    mb: 48,
  },
};

export default Instructions;
