import styled from 'styled-components';

const InstructionsWrapper = styled.div`
   {
    margin-top: 120px;
    line-height: 1.6;
  }

  p {
    padding-left: 2px;
  }

  ol {
    padding-left: 18px;
  }

  ul {
    padding-left: 32px;
  }

  ol li {
    list-style-type: decimal;
    margin-bottom: 24px;
  }

  ul li {
    list-style-type: inherit;
  }

  img {
    margin: 12px 0 0 0;
  }

  h2 {
    text-align: left;
    font-size: 28px;
    font-weight: 500;
    color: #0f2137;
    letter-spacing: -0.025em;
    margin-top: 40px;
    margin-bottom: 0;
    line-height: 1.67;
  }

  h3 {
    text-align: left;
    font-size: 22px;
    font-weight: 500;
    color: #0f2137;
    letter-spacing: -0.025em;
    margin-top: 40px;
    margin-bottom: 8px;
    line-height: 1.67;
  }

  a:hover {
    text-decoration: underline;
  }
`;

export default InstructionsWrapper;
